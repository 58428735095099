import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../../../_services/auth.service";
import {ToastrService} from "ngx-toastr";
import {SubjectsService} from "../../../../../_services/subjects.service";
import {AccessoriesService} from "../../../../../common/services/accessories.service";
import {isPlatformBrowser} from "@angular/common";
import {CarsService} from "../../../../../common/services/cars.service";
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'app-auth-register',
  templateUrl: './auth-register.component.html',
  styleUrls: ['./auth-register.component.css']
})
export class AuthRegisterComponent implements OnInit, OnDestroy {

  registerForm: FormGroup | undefined;
  submitted = false;
  loader = false;
  register$: any;
  updateFavoriteAccessories$: any;
  updateGarage$: any;
  passwordStatus = 'hide';

  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private subjectsService: SubjectsService,
    private accessoriesService: AccessoriesService,
    @Inject(PLATFORM_ID) private platformId: any,
    private carsService: CarsService
  ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      terms: [false, [Validators.requiredTrue]],
      terms2: [false, [Validators.requiredTrue]],
    });
  }

  get f() { return this.registerForm?.controls; }

  onSubmit() {
    this.loader = true;

    this.submitted = true;
    if (this.registerForm?.invalid) {
      this.loader = false;
      return;
    }
    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      if (token) {
        this.register$ = this.authService.userRegister(this.registerForm?.value)
          .subscribe(
            (data: any) => {
              if (data.response) {
                this.subjectsService.openAuthModal.next(true);
                this.subjectsService.userLoggedIn.next(true);
                this.registerForm?.reset();

                let accessoriesIDs = [];
                if (isPlatformBrowser(this.platformId)) {
                  if (localStorage.getItem('favoritesAccessories')) {
                    accessoriesIDs = JSON.parse(localStorage.getItem('favoritesAccessories')).map(function(item) {
                      return item.id;
                    });
                  }
                }
                if (accessoriesIDs.length > 0) {
                  this.saveFavoriteAccessories(accessoriesIDs)
                }

                let garageCarsIDs = [];
                if (isPlatformBrowser(this.platformId)) {
                  if (localStorage.getItem('parkedCars')) {
                    garageCarsIDs = JSON.parse(localStorage.getItem('parkedCars')).map(function(item) {
                      return item.id;
                    });
                  }
                }
                if (garageCarsIDs.length > 0) {
                  this.saveGarage(garageCarsIDs)
                }
              } else {
                this.toastr.error(data.errorCode.message);
              }
              this.loader = false;
              this.submitted = false;
            },
            (error: any) => {
              this.loader = false;
              this.submitted = false;
              this.toastr.error(error);
            });
      } else {
        this.loader = false;
        this.submitted = false;
      }
    });
  }

  saveFavoriteAccessories(accessoriesIDs) {
    this.updateFavoriteAccessories$ = this.accessoriesService.updateFavoriteAccessories({accessoriesIDs: accessoriesIDs})
      .subscribe(
        (data: any) => {
          if (data.response) {

          }
        },
        (error: any) => {
        });
  }

  saveGarage(carsIDs) {
    this.updateGarage$ = this.carsService.updateParkedCars({carIDs: carsIDs})
      .subscribe(
        (data: any) => {
        },
        (error: any) => {
        });
  }

  showHidePassword() {
    if (isPlatformBrowser(this.platformId)) {
      var x = (<HTMLInputElement>document.getElementById('showHidePassword'));
      if (x.type === "password") {
        x.type = "text";
        this.passwordStatus = 'show';
      } else {
        x.type = "password";
        this.passwordStatus = 'hide';
      }
    }
  }

  changeTab(type: string) {
    this.subjectsService.changeAuthTab.next(type)
  }

  ngOnDestroy(): void {
    if (this.updateFavoriteAccessories$) {
      this.updateFavoriteAccessories$.unsubscribe();
    }

    if (this.updateGarage$) {
      this.updateGarage$.unsubscribe();
    }
  }
}
