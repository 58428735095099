import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../../../_services/auth.service";
import {ToastrService} from "ngx-toastr";
import {SubjectsService} from "../../../../../_services/subjects.service";
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'app-auth-recover',
  templateUrl: './auth-recover.component.html',
  styleUrls: ['./auth-recover.component.css']
})
export class AuthRecoverComponent implements OnInit, OnDestroy {

  recoverForm: FormGroup | undefined;
  submitted = false;
  loader = false;
  recover$: any;

  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private subjectsService: SubjectsService,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  ngOnInit(): void {
    this.recoverForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get f() { return this.recoverForm?.controls; }

  onSubmit() {
    this.loader = true;
    this.submitted = true;

    if (this.recoverForm?.invalid) {
      this.loader = false;
      return;
    }


    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      if (token) {
        this.recover$ = this.authService.resetPassword(this.recoverForm?.value)
          .subscribe(
            (data: any) => {
              if (data.errorCode.code == 0) {
                this.subjectsService.openAuthModal.next(true);
                this.toastr.success('Verifică adresa de email pentru resetarea parolei!')
                this.recoverForm?.reset();
              } else {
                this.toastr.error(data.errorCode.message);
              }
              this.loader = false;
              this.submitted = false;
            },
            (error: any) => {
              this.loader = false;
              this.submitted = false;
              this.toastr.error(error);
            });
      } else {
        this.loader = false;
        this.submitted = false;
      }
    });

  }

  changeTab(type: string) {
    this.subjectsService.changeAuthTab.next(type)
  }

  ngOnDestroy(): void {
    if (this.recover$) {
      this.recover$.unsubscribe();
    }
  }

}
