<div class="registerForm">
  <div class="row noMargin">
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 noPadding">
      <div class="image">
        <img src="assets/images/registerModal.jpg" alt="">
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 noPadding">
      <form id="registerForm" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <h3 class="mb_corpo_a_title_condregular">
          Inregistrare
          <span></span>
        </h3>
        <div class="form-group">
          <div class="input">
            <label>Nume complet</label>
            <input class="form-control" type="text" placeholder="ex: Ion Miclean" formControlName="name" [ngClass]="{ 'invalid': submitted && f.name.errors }">
            <img src="assets/images/icons/user_white.svg" alt="">
          </div>
          <div class="form-error">
            <div *ngIf="submitted && f.name.errors && f.name.errors.required">Campul este obligatoriu!</div>
          </div>
        </div>
        <div class="form-group">
          <div class="input">
            <label>Adresa email</label>
            <input class="form-control" type="email"  placeholder="ex: example@gmail.com" formControlName="email" [ngClass]="{ 'invalid': submitted && f.email.errors }">
            <img src="assets/images/icons/email_white.svg" alt="">
          </div>
          <div class="form-error">
            <div *ngIf="submitted && f.email.errors && f.email.errors.required">Campul este obligatoriu!</div>
            <div *ngIf="submitted && f.email.errors && f.email.errors.email">Adresa de email este invalidă!</div>
          </div>
        </div>
        <div class="form-group">
          <div class="input">
            <label>Parolă</label>
            <input id="showHidePassword" class="form-control" type="password" placeholder="Introdu parola" formControlName="password" [ngClass]="{ 'invalid': submitted && f.password.errors }">
            <i (click)="showHidePassword()" *ngIf="passwordStatus == 'show'" class="fa fa-eye-slash" aria-hidden="true"></i>
            <i (click)="showHidePassword()" *ngIf="passwordStatus == 'hide'" class="fa fa-eye" aria-hidden="true"></i>
            <img src="assets/images/icons/lock_white.svg" alt="">
          </div>
          <div class="form-error">
            <div *ngIf="submitted && f.password.errors && f.password.errors.required">Câmpul este obligatoriu!</div>
            <div *ngIf="submitted && f.password.errors && f.password.errors.minlength">Parola trebuie să conțină cel puțin 6 caractere!</div>
          </div>
        </div>
        <div class="form-group">
          <label class="checkboxContainer"> <span class="text">Sunt de acord cu <a target="_blank"  routerLink="/termeni-si-conditii">Termenii si Conditiile</a></span>
            <input formControlName="terms" type="checkbox">
            <span class="checkmark"></span>
          </label>
          <div class="form-error">
            <div *ngIf="submitted && f.terms.errors">Câmpul este obligatoriu!</div>
          </div>
        </div>

        <div class="form-group">
          <label class="checkboxContainer"> <span class="text">Am citit și înțeles <a target="_blank" routerLink="/politica-de-confidentialitate">Declarația de consimțământ</a> și sunt de acord cu colectarea, utilizarea și divulgarea datelor mele personale către Autoklass Center SRL. </span>
            <input formControlName="terms2" type="checkbox">
            <span class="checkmark"></span>
          </label>
          <div class="form-error">
            <div *ngIf="submitted && f.terms2.errors">Câmpul este obligatoriu!</div>
          </div>
        </div>
        <div class="buttons">
          <button type="submit" class="btn btn-primary-custom">
            <span *ngIf="!loader">Creează cont<img src="assets/images/icons/arrow_right.svg" alt=""></span>
            <div *ngIf="loader" class="lds-ring"><div></div><div></div><div></div><div></div></div>
          </button>
        </div>
        <p class="noAccount">
          Ai deja cont? <span (click)="changeTab('login')">Click aici</span> pentru inregistrare
        </p>
      </form>
    </div>
  </div>
</div>
