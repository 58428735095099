<div class="modal fade" id="authModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div *ngIf="activeTab == 1" class="activeTab">
        <app-auth-login></app-auth-login>
      </div>
      <div *ngIf="activeTab == 2" class="activeTab">
        <app-auth-register></app-auth-register>
      </div>
      <div *ngIf="activeTab == 3" class="activeTab">
        <app-auth-recover></app-auth-recover>
      </div>
    </div>
  </div>
</div>
<button style="display:none;" id="authModalButton" type="button" class="btn btn-primary" data-toggle="modal" data-target="#authModal">
  Launch demo modal
</button>
