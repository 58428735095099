import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SubjectsService {

  activeLinkSubject = new Subject();
  openAuthModal = new Subject();
  changeAuthTab = new Subject();
  userLoggedIn = new Subject();
  getCarsOffer = new Subject();
  openModelDetailsModal = new Subject();
  addToFavorite = new Subject();
  addToCart = new Subject();
  checkCartUser = new Subject();
  getCartUser = new Subject();
  addToGarage = new Subject();
  openJourneyModal = new Subject();
  closeMobileMenu = new Subject();
  changeHomePadding = new Subject();
  openImageFullscreen = new Subject();
  changeFullScreenImage = new Subject();
  closeContactModal = new Subject();
  getLeasingOffers = new Subject();
  getLeasingOffers2 = new Subject();
  getWinterSales = new Subject();
  getSpringDeals = new Subject();
  constructor(
  ) { }


}


