<div class="loginForm">
  <div class="row noMargin">
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 noPadding">
      <div class="image">
        <img src="assets/images/loginModal.jpeg" alt="">
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 noPadding">
      <form id="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <h3 class="mb_corpo_a_title_condregular">
          Autentificare
          <span></span>
        </h3>
        <div class="form-group">
          <div class="input">
            <label>Adresa email</label>
            <input class="form-control" type="email" placeholder="ex: example@gmail.com" formControlName="email"  [ngClass]="{ 'invalid': submitted && f.email.errors }">
            <img src="assets/images/icons/email_white.svg" alt="">
          </div>
          <div class="form-error">
            <div *ngIf="submitted && f.email.errors && f.email.errors.required">Campul este obligatoriu!</div>
            <div *ngIf="submitted && f.email.errors && f.email.errors.email">Adresa de email este invalidă!</div>
          </div>
        </div>
        <div class="form-group second">
          <div class="input">
            <label>Parolă</label>
            <input class="form-control" type="password" placeholder="Introdu parola" formControlName="password" [ngClass]="{ 'invalid': submitted && f.password.errors }">
            <img src="assets/images/icons/lock_white.svg" alt="">
          </div>
          <div class="form-error">
            <div *ngIf="submitted && f.password.errors && f.password.errors.required">Câmpul este obligatoriu!</div>
            <div *ngIf="submitted && f.password.errors && f.password.errors.minlength">Parola trebuie să conțină cel puțin 6 caractere!</div>
          </div>
        </div>
        <p (click)="changeTab('recover')" class="forgotPassword">
          Am uitat parola
        </p>
        <div class="buttons">
          <button type="submit" class="btn btn-primary-custom">
            <span *ngIf="!loader">Login <img src="assets/images/icons/arrow_right.svg" alt=""></span>
            <div *ngIf="loader" class="lds-ring"><div></div><div></div><div></div><div></div></div>
          </button>
        </div>
        <p class="noAccount">
          Nu ai cont? <span (click)="changeTab('register')">Click aici</span> pentru inregistrare
        </p>
      </form>
    </div>
  </div>
</div>
