<div *ngIf="popupContent" class="modal fade" id="popupModal" tabindex="-1" role="dialog" aria-labelledby="popupModal" aria-hidden="true" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="popupContent" [ngClass]="{'isCustom': isCustom}">
        <div class="popupImage">
          <img class="closeImage" data-dismiss="modal" aria-label="Close" src="assets/images/icons/closeButton.png" alt="Close">
          <h2 *ngIf="!isCustom" class="mb_corpo_a_title_condregular">
            <span></span>
            {{ popupContent.title }}
          </h2>
          <img *ngIf="popupContent.image && !isCustom" src="{{ getMediaURL(popupContent.image) }}" alt="Background">
          <img *ngIf="isCustom" src="assets/images/custom.jpeg" alt="Background">
          <div class="popupBackground"></div>
        </div>
        <div class="text" >
          <h3 *ngIf="!isCustom" class="mb_corpo_a_title_condregular">
            {{ popupContent.subtitle }}
            <span></span>
          </h3>
          <p *ngIf="!isCustom">
            {{ popupContent.content }}
          </p>
          <div *ngIf="isCustom" class="customText">
            <p>
              Stimati clienti,
            </p>
            <p>
              Deoarece continuam sa ne modernizam pentru dumneavoastra, va informam ca sucursala Autoklass Bucuresti Sud va fi inchisa sambata, 6 aprilie 2024.
            </p>
            <p>
              Ne cerem scuze pentru disconfortul creat!
            </p>
            <p>
              Echipa Autoklass
            </p>
          </div>
          <a *ngIf="!isCustom" (click)="closeModal()" href="{{ popupContent.buttonLink }}" target="_blank" class="btn btn-search mb_corpo_s_titleregular" >{{ popupContent.buttonText }} <span><i class="fa fa-chevron-right" aria-hidden="true"></i></span></a>
        </div>
      </div>
    </div>
  </div>
</div>
<button style="display:none;" id="popupModalButton" type="button" class="btn btn-primary" data-toggle="modal" data-target="#popupModal">
  Launch demo modal
</button>
