<div class="d-flex">
  <ul>
    <li>
      <a href="/stoc-auto"><span [inlineSVG]="'assets/images/icons/car-stoc.svg'"></span>
        <span>Vezi tot stocul</span></a>
    </li>
    <li>
      <a href="/servicii/programare-service"> <span [inlineSVG]="'assets/images/icons/service.svg'"></span>
        <span>Programare service</span></a>
    </li>
    <li>
      <a href="/servicii/test-drive"> <span [inlineSVG]="'assets/images/icons/test-drive.svg'"></span>
        <span>Programare test drive</span></a>
    </li>
  </ul>
<div class="column"></div>
</div>
