import {AfterViewChecked, AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {GeneralService} from "./_services/general.service";
import {UtilHelper} from "./_helpers/util.helper";
import {isPlatformBrowser, PlatformLocation} from '@angular/common';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  title = 'Autoklass';

  getAppSettings$: any;
  currentUrl: string;

  constructor(
    private generalService: GeneralService,
    private platformLocation: PlatformLocation,
    @Inject(PLATFORM_ID) private platformId: any,
    private location: Location
  ) {
    if(this.platformLocation && this.platformLocation['location']){
      localStorage.setItem('origin',this.platformLocation['location'].origin);
    }

  }

  ngOnInit(): void {
    this.currentUrl = this.location.path();
    this.getAppSettings();
  }

  getAppSettings() {
    this.getAppSettings$ = this.generalService.getAppSettings()
      .subscribe(
        (res: any) => {
        },
        (error: string | undefined) => {
        }
      );
  }

  ngOnDestroy(): void {
    if (this.getAppSettings$) {
      this.getAppSettings$.unsubscribe();
    }
  }

  ngAfterViewChecked() {
    if (isPlatformBrowser(this.platformId)) {
      UtilHelper.setAutocompleteOff();
    }
  }
}
