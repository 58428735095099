<div class="loginForm">
  <div class="row noMargin">
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 noPadding">
      <div class="image">
        <img src="assets/images/loginModal.jpeg" alt="">
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 noPadding">
      <form id="loginForm" [formGroup]="recoverForm" (ngSubmit)="onSubmit()">
        <h3 class="mb_corpo_a_title_condregular">
          Recuperare parolă
          <span></span>
        </h3>
        <p class="subtitle">
          Introdu email-ul contului tau Autoklass pentru a primi link-ul de resetare parola.
        </p>
        <div class="form-group">
          <div class="input">
            <label>Adresa email</label>
            <input class="form-control" type="email"  placeholder="ex: example@gmail.com" formControlName="email" [ngClass]="{ 'invalid': submitted && f.email.errors }">
            <img src="assets/images/icons/email_white.svg" alt="">
          </div>
          <div class="form-error">
            <div *ngIf="submitted && f.email.errors && f.email.errors.required">Campul este obligatoriu!</div>
            <div *ngIf="submitted && f.email.errors && f.email.errors.email">Adresa de email este invalidă!</div>
          </div>
        </div>

        <div class="buttons">
          <button type="submit" class="btn btn-primary-custom">
            <span *ngIf="!loader">Trimite email <img src="assets/images/icons/arrow_right.svg" alt=""></span>
            <div *ngIf="loader" class="lds-ring"><div></div><div></div><div></div><div></div></div>
          </button>
        </div>
        <p class="noAccount">
          Ai cont? <span (click)="changeTab('login')">Click aici</span> pentru autentificare
        </p>
      </form>
    </div>
  </div>
</div>
