import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class CookieCustomService {
  public expireDate: Date = new Date(new Date().getTime() + 24 * 60 * 60 * 1000); //next Day
  constructor(
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }

  public getItem(name) {
    if (isPlatformBrowser(this.platformId)) {
      if (['token', 'expiredToken', 'code'].indexOf(name) !== -1) {
        return this.cookieService.get(name);
      }
      return localStorage.getItem(name);
    } else if (name === 'fileStorage') {
      const tempValue = {url: ''};
      return JSON.stringify(tempValue);
    }

  }

  public removeItem(name) {
    if (isPlatformBrowser(this.platformId)) {
      if (['token', 'expiredToken'].indexOf(name) !== -1) {
        this.cookieService.delete(name);
      } else {
        localStorage.removeItem(name);
      }
    }
  }

  public setItem(name, value) {
    if (isPlatformBrowser(this.platformId)) {
      this.setExpireCookieDate();
      if (['token', 'expiredToken'].indexOf(name) !== -1) {
        this.cookieService.set(name, value, this.expireDate, '/', window.location.hostname, false, "Strict");
      } else if (name === 'code') {
        var expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 1);
        this.cookieService.set(name, value, expireDate, '/', window.location.hostname, false, "Strict");
      } else {
        localStorage.setItem(name, value);
      }
    }
  }

  public clear() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
      this.cookieService.deleteAll('/', window.location.hostname);
    }
  }

  private setExpireCookieDate() {
    if (isPlatformBrowser(this.platformId)) {
      const ttl = parseInt(this.cookieService.get('ttl'));
      if (ttl && !isNaN(ttl)) {
        this.expireDate = new Date(new Date().getTime() + ttl * 1000);
      }
    }
  }
}
