import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {SubjectsService} from "../../../../_services/subjects.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.css']
})
export class AuthModalComponent implements OnInit, OnDestroy {
  activeTab = 1;
  destroySubject$: Subject<void> = new Subject();

  constructor(
    private subjectsService: SubjectsService,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  ngOnInit(): void {
    const self = this;

    this.subjectsService.openAuthModal
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(function(val) {
      if (val) {
        self.activeTab = 1;
        self.openModal();
      }
    });

    this.subjectsService.changeAuthTab
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(function(val) {
      if (val && val == 'login') {
        self.activeTab = 1;
      } else if (val && val == 'register') {
        self.activeTab = 2;
      } else if (val && val == 'recover') {
        self.activeTab = 3;
      }
    })
  }

  openModal() {
    if (isPlatformBrowser(this.platformId)) {
      const authModalButton = document.getElementById('authModalButton') as HTMLElement;
      authModalButton.click();
    }
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.unsubscribe();
  }

}
