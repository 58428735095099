import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {CarsModel} from '../_models/cars/cars';
import {CookieCustomService} from '../_services/cookie.custom.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})

export class UtilHelper {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private localStorage: CookieCustomService
  ) {

  }

  static taxaLivrare = 25;

  static setAutocompleteOff() {
    const inputsEl = $('input[autocomplete!="new-fieldname"]');
    if (!inputsEl.length) {
      return;
    }
    for (let i = 0; i < inputsEl.length; i++) {
      const el = inputsEl[i];
      inputsEl[i].setAttribute('autocomplete', 'new-password');
      inputsEl[i].setAttribute('autocorrect', 'off');
      // el.autocorrect = el.autocomplete = el.autocapitalize = el.spellcheck = false;
      $(el).prop('spellcheck', false);
    }
  }


  static checkUrl(s) {
    const regexp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    return regexp.test(s);
  }


  static cleanPhoneNumber(inputValue: string): string {
    if (!inputValue.length) {
      return '';
    }

    let outputValue = inputValue;
    if (outputValue.startsWith('001')) {
      outputValue = outputValue.substr(3);
    }
    if (outputValue.startsWith('+1')) {
      outputValue = outputValue.substr(2);
    }
    const regex = /\D/g;
    outputValue = outputValue.replace(regex, '');

    return outputValue;

  }

  public static validatePhone(value?: string): boolean {
    if (!value) {
      return true;
    }
    return value.length == -10;
  }


  static setCarUrl(car: CarsModel) {
    const brand = (car.brand ? car.brand.slug : 'nobrand');
    const model = (car.model ? car.model.slug : 'nomodel');
    const origin = localStorage.getItem('origin') ? localStorage.getItem('origin') : '';
    return '/masini/' + brand + '/' + model + '/' + car.slug;
  }

  static setSimpleURL(slug: string, type: string) {
    return '/' + type + '/' + slug;
  }


  static mainContactInfo() {
    return {
      address: 'Splaiul Unirii 166A, 040042 Bucuresti',
      email: 'office@autoklass.ro',
      callCenter: '+40 317 133333',
      phone: '+40 317 133333',
      mapUrl: 'https://goo.gl/maps/GWCd1bdgdmMGbXp47'
    };
  }


  static capitalizeFirstLetter(item: string) {
    const temp = item.split(' ');
    const tempA = temp.map(itemTemp => {
      return itemTemp.charAt(0).toUpperCase() + itemTemp.slice(1);
    });
    return tempA.join(' ');
  }

}
