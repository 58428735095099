import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from '../_services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    public authService: AuthService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      let error = '';
      if (err.status === 401) {
        this.authService.logout();
        error = 'Unauthorized access!';
      } else if (err.status === 403) {
        error = 'Your credentials are incorrect!';
      } else {
        console.log(err);
        error = 'Something went wrong!';
      }
      return throwError(error);
    }));
  }

}
