import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {isPlatformBrowser} from "@angular/common";

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
  'Access-Control-Allow-Origin': '*'
});

@Injectable({
  providedIn: 'root'
})

export class AccessoriesService {

  constructor(
    private http: HttpClient
  ) { }

  getWheelDimensions(filters) {
    let params = new HttpParams();

    if (filters.categoryName) {
      params = params.append('categoryName' , filters.categoryName);
    }

    if (filters.middleCategoryName) {
      params = params.append('middleCategoryName' , filters.middleCategoryName);
    }

    if (filters.subCategoryID) {
      params = params.append('subCategoryID' , filters.subCategoryID);
    }

    if (filters.keyword) {
      params = params.append('keyword' , filters.keyword);
    }

    return this.http.get( environment.apiUrl + '/accessories/wheelDimensions', {params, headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getAccessories(filters) {

    let params = new HttpParams();

    if (filters.wheelDimension) {
      params = params.append('wheelDimension' , filters.wheelDimension);
    }

    if (filters.limit) {
      params = params.append('limit' , filters.limit);
    }

    if (filters.order) {
      params = params.append('order' , filters.order);
    }

    if (filters.type) {
      params = params.append('type' , filters.type);
    }

    if (filters.carBrandID) {
      params = params.append('carBrandID' , filters.carBrandID);
    }

    if (filters.categoryName) {
      params = params.append('categoryName' , filters.categoryName);
    }

    if (filters.middleCategoryName) {
      params = params.append('middleCategoryName' , filters.middleCategoryName);
    }

    if (filters.subCategoryID) {
      params = params.append('subCategoryID' , filters.subCategoryID);
    }

    if (filters.skip) {
      params = params.append('skip' , filters.skip);
    }

    if (filters.keyword) {
      params = params.append('keyword' , filters.keyword);
    }

    return this.http.get( environment.apiUrl + '/accessories/accessoriesSearch', {params, headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getAccessoriesCounter(filters) {

    let params = new HttpParams();

    if (filters.type) {
      params = params.append('type' , filters.type);
    }

    if (filters.wheelDimension) {
      params = params.append('wheelDimension' , filters.wheelDimension);
    }

    if (filters.carBrandID) {
      params = params.append('carBrandID' , filters.carBrandID);
    }

    if (filters.categoryName) {
      params = params.append('categoryName' , filters.categoryName);
    }

    if (filters.middleCategoryName) {
      params = params.append('middleCategoryName' , filters.middleCategoryName);
    }

    if (filters.subCategoryID) {
      params = params.append('subCategoryID' , filters.subCategoryID);
    }

    if (filters.keyword) {
      params = params.append('keyword' , filters.keyword);
    }

    return this.http.get( environment.apiUrl + '/accessories/accessoriesSearchCounter', {params, headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  setFilters(filters: any, type: string) {
    const queryParams = [];
    const properties = Object.getOwnPropertyNames(filters);
    for (const key in properties) {
      const item = properties[key];
      if (item === 'limit') {
        queryParams.push(item + '=' + filters[item]);
      } else if (item === 'skip') {
        queryParams.push(item + '=' + filters[item]);
      } else if (filters[item]) {
        if (typeof filters[item] === 'object' && filters[item].length > 0) {
          for (let kk = 0; kk < filters[item].length; kk++) {
            const filterVal= filters[item][kk];
            if(filterVal && filterVal['id']){
              queryParams.push(item + '=' + filterVal['id']);
            }else{
              queryParams.push(item + '=' + filterVal);
            }
          }
        } else if (typeof filters[item] !== 'object') {
          queryParams.push(item + '=' + filters[item]);
        }

      }
    }
    let url = environment.apiUrl  + '/accessories/' + type + '?';
    if (queryParams.length) {
      url += '&' + queryParams.join('&');
    }

    url = url.replace('?&', '?');
    return url;
  }

  getAccessoriesOffers(filters) {

    let params = new HttpParams();

    if (filters.limit) {
      params = params.append('limit' , filters.limit);
    }

    if (filters.order) {
      params = params.append('order' , filters.order);
    }

    if (filters.type) {
      params = params.append('type' , filters.type);
    }

    if (filters.category) {
      params = params.append('category' , filters.category);
    }

    if (filters.skip) {
      params = params.append('skip' , filters.skip);
    }

    return this.http.get( this.setFilters(filters, 'accessoriesSearchOffers'), {headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getAccessoriesOffersCounter(filters) {

    let params = new HttpParams();

    if (filters.order) {
      params = params.append('order' , filters.order);
    }

    if (filters.category) {
      params = params.append('category' , filters.category);
    }

    if (filters.type) {
      params = params.append('type' , filters.type);
    }

    return this.http.get(this.setFilters(filters, 'accessoriesSearchOffersCounter'), {headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getRelatedAccessories(filters: any) {
    let params = new HttpParams().set('id', filters.id);

    if (filters.accessoriesNumber) {
      params = params.append('accessoriesNumber' , filters.accessoriesNumber);
    }

    return this.http.get( environment.apiUrl + '/accessories/getRelatedAccessories', {params, headers})
      .pipe(map((res: any) => {
        return res;
      }));

  }

  getAccessory(slug) {
    let params = new HttpParams().set('slug' , slug);

    return this.http.get( environment.apiUrl + '/accessories/getAccessory', {params, headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getCategories() {
    return this.http.get( environment.apiUrl + '/accessories/misc/getCategories', {headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  saveAccessoryToFavorite(data) {
    return this.http.post( environment.apiUrl + '/accessories/setFavoriteAccessory', data, {headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  removeAccessoryFromFavorite(data) {
    return this.http.post( environment.apiUrl + '/accessories/removeFavoriteAccessory', data, {headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getFavoriteAccessories() {
    return this.http.get( environment.apiUrl + '/accessories/getFavoriteAccessories', {headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  updateFavoriteAccessories(data) {
    return this.http.post( environment.apiUrl + '/accessories/updateFavoriteAccessories', data, {headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  removeAllFavorite() {
    return this.http.post(environment.apiUrl + '/accessories/removeAllFavorite', {}, {headers}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }


}


