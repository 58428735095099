import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {isPlatformBrowser} from '@angular/common';
import {CookieCustomService} from "./cookie.custom.service";
import {UsersModel} from "../_models/users/users";
import {SubjectsService} from "./subjects.service";

const headers = new HttpHeaders({
  'Content-Type':  'application/json'
});

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private isAuthenticated: boolean;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any,
    private localStorage: CookieCustomService,
    private subjectsService: SubjectsService
  ) { }

  getToken(): string {
    if (isPlatformBrowser(this.platformId)) {
      if (this.localStorage.getItem('token')) {
        return JSON.parse(this.localStorage.getItem('token'));
      }
    }
  }

  getUser(): string {
    if (isPlatformBrowser(this.platformId)) {
      if (this.localStorage.getItem('currentUser')) {
        return JSON.parse(this.localStorage.getItem('currentUser'));
      }
    }
  }

  getUserObject(): UsersModel {
    if (isPlatformBrowser(this.platformId)) {
      if (this.localStorage.getItem('currentUser')) {
        return JSON.parse(this.localStorage.getItem('currentUser'));
      }
    }
  }

  getUserRole(): string {
    if (isPlatformBrowser(this.platformId)) {
      if (this.localStorage.getItem('currentUser')) {
        return JSON.parse(this.localStorage.getItem('currentUser')).role;
      }
    }
  }

  getUserAccount() {
    return this.http.get( environment.apiUrl + '/users/getUserByToken', {headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  logout() {
    this.http.post(environment.apiUrl + '/users/logout', {});
    this.localStorage.removeItem('currentUser');
    this.localStorage.removeItem('token');
    this.localStorage.removeItem('ttl');
    this.localStorage.removeItem('favoritesAccessories');
    this.localStorage.removeItem('parkedCars');
    if (isPlatformBrowser(this.platformId)) {
      window.location.reload();
    }
    return;
  }

  public authenticated(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      if (this.localStorage.getItem('token') && this.localStorage.getItem('currentUser')) {
        return this.isAuthenticated = true;
      }
    }
  }

  userLogin(data: any) {
    return this.http.post( environment.apiUrl + '/users/login', data, {headers})
      .pipe(map((res: any) => {
        if (res.response && res.response.id) {
          if (isPlatformBrowser(this.platformId)) {
            this.localStorage.setItem('currentUser', JSON.stringify(res.response.user));
            this.localStorage.setItem('token', JSON.stringify(res.response.id));
          }
        }
        return res;
      }));
  }

  resetPassword(data: any) {
    return this.http.post( environment.apiUrl + '/users/resetPassword', data, {headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  updateUser(data: any) {
    return this.http.post( environment.apiUrl + '/users/updateObject', data, {headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  changePassword(data: any) {
    return this.http.post( environment.apiUrl + '/users/changePassword', data, {headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  setPassword(data: any) {
    return this.http.post( environment.apiUrl + '/users/setPassword', data, {headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  userRegister(data: any) {
    if (data.terms) {
      data.terms = 1;
    }

    if (data.terms2) {
      data.consent = 1;
    }
    return this.http.post( environment.apiUrl + '/users/register', data, {headers})
      .pipe(map((res: any) => {
        if (res.response && res.response.id) {
          if (isPlatformBrowser(this.platformId)) {
            this.localStorage.setItem('currentUser', JSON.stringify(res.response.user));
            this.localStorage.setItem('token', JSON.stringify(res.response.id));
          }
        }
        return res;
      }));
  }

  registerViaOrder(data: any) {
    if (data.terms1) {
      data.terms = 1;
    }

    if (data.terms2) {
      data.consent = 1;
    }

    return this.http.post( environment.apiUrl + '/users/registerForOrder', data, {headers})
      .pipe(map((res: any) => {
        if (res.response && res.response.id) {
          if (isPlatformBrowser(this.platformId)) {
            this.localStorage.setItem('currentUser', JSON.stringify(res.response.user));
            this.localStorage.setItem('token', JSON.stringify(res.response.id));
          }
        }
        return res;
      }));
  }


}


