import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {isPlatformBrowser} from "@angular/common";
import {Observable} from 'rxjs';

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
  'Access-Control-Allow-Origin': '*'
});

@Injectable({
  providedIn: 'root'
})

export class GeneralService {

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  getAppSettings() {
    return this.http.get(environment.apiUrl + '/settings/apiSettings', {headers})
      .pipe(map((res: any) => {
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem('fileStorage', JSON.stringify(res.response.fileStorage));
        }
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem('appSettings', JSON.stringify(res.response));
        }
        return res;
      }));
  }

  getPopup() {
    return this.http.get(environment.apiUrl + '/settings/getPopup', {headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getCarousel(title) {

    let params = new HttpParams().set('title' , title);
    return this.http.get(environment.apiUrl + '/settings/getCarousel', {params, headers})
      .pipe(map((res: any) => {
        return res;
      }));

  }

  saveRamburs(data: any) {
    return this.http.post(environment.apiUrl + '/ramburs/submit', data, {headers}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  submitFormulareInterne(data: any) {
    return this.http.post(environment.apiUrl + '/contact/submitFormulareInterne', data, {headers}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  submitContactGDPR(data: any) {
    return this.http.post(environment.apiUrl + '/contact/submitContactGDPR', data, {headers}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  download(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    });
  }

}


