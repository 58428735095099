import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../../../_services/auth.service";
import {ToastrService} from "ngx-toastr";
import {SubjectsService} from "../../../../../_services/subjects.service";
import {AccessoriesService} from "../../../../../common/services/accessories.service";
import {isPlatformBrowser} from "@angular/common";
import {CarsService} from "../../../../../common/services/cars.service";
import {CookieCustomService} from "../../../../../_services/cookie.custom.service";

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.css']
})
export class AuthLoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup | undefined;
  submitted = false;
  loader = false;
  login$: any;
  getFavoriteAccessories$: any;
  updateFavoriteAccessories$: any;
  getParkedCars$: any;
  updateGarage$: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private subjectsService: SubjectsService,
    private accessoriesService: AccessoriesService,
    @Inject(PLATFORM_ID) private platformId: any,
    private carsService: CarsService,
    private localStorage: CookieCustomService
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  get f() { return this.loginForm?.controls; }

  onSubmit() {
    this.loader = true;
    this.submitted = true;
    if (this.loginForm?.invalid) {
      this.loader = false;
      return;
    }

    this.login$ = this.authService.userLogin(this.loginForm?.value)
      .subscribe(
        (data: any) => {
          if (data.response) {
            this.subjectsService.openAuthModal.next(true);
            this.subjectsService.userLoggedIn.next(true);
            this.loginForm?.reset();
            this.getFavoriteAccessories();
            this.getParkedCars();
          } else {
            this.toastr.error(data.errorCode.message);
          }
          this.loader = false;
          this.submitted = false;
        },
        (error: any) => {
          this.loader = false;
          this.submitted = false;
          this.toastr.error(error);
        });

  }

  getParkedCars() {
    this.getParkedCars$ = this.carsService.getParkedCars()
      .subscribe(
        (data: any) => {
          if (data.response) {
            const savedCars = data.response;
            const savedCarsIDs = data.response.map(function(item) {
              return item.id;
            });
            let cars = [];
            if (isPlatformBrowser(this.platformId)) {
              if (this.localStorage.getItem('parkedCars')) {
                cars = JSON.parse(this.localStorage.getItem('parkedCars'));
              }
            }

            for (let i = 0; i < cars.length; i++) {
              if (savedCarsIDs.indexOf(cars[i].id) < 0) {
                savedCars.push(cars[i]);
              }
            }

            this.saveGarage(savedCars);
          }
        },
        (error: any) => {
        });
  }

  saveGarage(savedCars: any) {
    const savedCarsIDs = savedCars.map(function(item) {
      return item.id;
    });

    this.updateGarage$ = this.carsService.updateParkedCars({carIDs: savedCarsIDs})
      .subscribe(
        (data: any) => {
          if (data.response) {
            this.localStorage.setItem('parkedCars', JSON.stringify(savedCars));
            this.subjectsService.addToGarage.next(true);
          }
        },
        (error: any) => {
        });
  }

  getFavoriteAccessories() {
    this.getFavoriteAccessories$ = this.accessoriesService.getFavoriteAccessories()
      .subscribe(
        (data: any) => {
          if (data.response) {
            const savedAccessories = data.response;
            const savedAccessoriesIDs = data.response.map(function(item) {
              return item.id;
            });
            let favorites = [];
            if (isPlatformBrowser(this.platformId)) {
              if (this.localStorage.getItem('favoritesAccessories')) {
                favorites = JSON.parse(this.localStorage.getItem('favoritesAccessories'));
              }
            }

            for (let i = 0; i < favorites.length; i++) {
              if (savedAccessoriesIDs.indexOf(favorites[i].id) < 0) {
                savedAccessories.push(favorites[i]);
              }
            }

            this.saveFavoriteAccessories(savedAccessories);
          }
        },
        (error: any) => {
        });
  }

  saveFavoriteAccessories(accessories: any) {
    const accessoriesIDs = accessories.map(function(item) {
      return item.id;
    });

    this.updateFavoriteAccessories$ = this.accessoriesService.updateFavoriteAccessories({accessoriesIDs: accessoriesIDs})
      .subscribe(
        (data: any) => {
          if (data.response) {
            this.localStorage.setItem('favoritesAccessories', JSON.stringify(accessories));
            this.subjectsService.addToFavorite.next(true);
          }
        },
        (error: any) => {
        });
  }

  changeTab(type: string) {
    this.subjectsService.changeAuthTab.next(type)
  }

  ngOnDestroy(): void {
    if (this.login$) {
      this.login$.unsubscribe();
    }

    if (this.getFavoriteAccessories$) {
      this.getFavoriteAccessories$.unsubscribe();
    }

    if (this.updateFavoriteAccessories$) {
      this.updateFavoriteAccessories$.unsubscribe();
    }

    if (this.getParkedCars$) {
      this.getParkedCars$.unsubscribe();
    }

    if (this.updateGarage$) {
      this.updateGarage$.unsubscribe();
    }
  }

}
