import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {ToastrModule} from "ngx-toastr";
import {AppRoutingModule} from "./app-routing.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks} from 'ng-lazyload-image';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {AuthModalComponent} from "./front/components/common/auth-modal/auth-modal.component";
import {AuthLoginComponent} from "./front/components/common/auth-modal/auth-login/auth-login.component";
import {AuthRegisterComponent} from "./front/components/common/auth-modal/auth-register/auth-register.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FrontWallComponent} from "./front/components/common/front-wall/front-wall.component";
import {TokenInterceptor} from "./_helpers/token.interceptor";
import {ErrorInterceptor} from "./_helpers/error.interceptor";
import {FloatingMenuComponent} from "./front/components/layouts/main-front/floating-menu/floating-menu.component";
import {InlineSVGModule} from "ng-inline-svg";
import {AuthRecoverComponent} from "./front/components/common/auth-modal/auth-recover/auth-recover.component";
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import localeRo from '@angular/common/locales/ro';
import {FrontPopupComponent} from "./front/components/common/front-popup/front-popup.component";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import {environment} from '../environments/environment';

registerLocaleData(localeRo);

@NgModule({
  declarations: [
    AppComponent,
    AuthModalComponent,
    AuthLoginComponent,
    AuthRegisterComponent,
    AuthRecoverComponent,
    FrontWallComponent,
    FloatingMenuComponent,
    FrontPopupComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      autoDismiss: true,
      timeOut: 5000,
      preventDuplicates: true
    }),
    LazyLoadImageModule,
    CarouselModule,
    ReactiveFormsModule,
    FormsModule,
    InlineSVGModule.forRoot(),
    RecaptchaV3Module
  ],
  providers: [
    {provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks},
    {
      provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true
    },
    { provide: LOCALE_ID, useValue: 'ro-RO'},
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
